// TODO: タイムゾーン変化時には通知をOFF→ONする必要あり。
// もしくはSWで時刻の異なる通知が来たら自動更新するなど...

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

// 時刻(時, 分)をUTC時に変換
export function get_utc_hour(HH, mm) {
  return dayjs(HH + ":" + mm, "HH:mm")
    .utc()
    .format("HH");
}

// 時刻(時, 分)をUTC時に変換
export function get_utc_min(HH, mm) {
  return dayjs(HH + ":" + mm, "HH:mm")
    .utc()
    .format("mm");
}
