
import Vue from "vue";

export default Vue.extend({
  name: "ToggleIcon",

  data: function () {
    return {};
  },
  props: [
    "value", // 値
    "type", // アイコンタイプ
    "color", // アイコンカラー
    "label", // ラベル
  ],
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
  methods: {
    on_toggle: function () {
      if (this.internalValue == null || this.internalValue == false) {
        this.internalValue = true;
      } else {
        this.internalValue = false;
      }
      this.$emit("change");
    },
  },
});
