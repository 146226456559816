
import Vue from "vue";
import DreamMain from "./DreamMain.vue";

export default Vue.extend({
  name: "DreamTop",

  components: {
    DreamMain,
  },
  data() {
    return {
      is_first_auth_done: false,
    };
  },
  created: async function () {
    // TODO: 関数化
    const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // index.html <head> で開始した自動ログインの処理完了を待機
    while (window.g_is_first_auth_done != true) {
      await _sleep(100);
    }
    this.is_first_auth_done = true;
  },
});
