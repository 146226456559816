import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    dark: true,
    themes: {
      light: {},
      dark: {
        // テーマ
        // https://vuetifyjs.com/ja/style/colors
        // https://material.io/design/color/#tools-for-picking-colors
        primary: "#009688", // teal (500) : 実際にはhyper link用
        secondary: "#3E2723", //brown darken-4 (900)
        accent: "#00796B", // teal darken-2 (700)
        error: "#D32F2F", // red darken-2 (700)
        warning: "#F57C00", // orange darken-2 (700)
        info: "#0288D1", // light-blue darken-2 (700)
        success: "#689F38", // light-green darken-2 (700)
      },
    },
  },
});
