
import Vue from "vue";
import DreamPage from "./DreamPage.vue";
import DreamSearch from "./DreamSearch.vue";
import DreamHelp from "./DreamHelp.vue";
import DreamSettings from "./DreamSettings.vue";

export default Vue.extend({
  name: "DreamMainView",

  components: {
    DreamPage,
    DreamSearch,
    DreamHelp,
    DreamSettings,
  },
  props: [
    "show_footer", // フッタの表示状態（親から子に渡すための中間)
  ],
  methods: {
    show_page: function (date) {
      this.$refs.page.show_day_page(date);
    },
  },
});
