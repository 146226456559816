
import Vue from "vue";
import CustomToolTip from "./CustomToolTip.vue";
import CustomTimePicker from "./CustomTimePicker.vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { get_utc_hour, get_utc_min } from "../plugins/dayjs_util.js";
import { idb } from "../plugins/idb";

dayjs.extend(customParseFormat);

export default Vue.extend({
  name: "DreamNotification",

  components: {
    CustomToolTip,
    CustomTimePicker,
  },

  data: function () {
    return {
      inner_notify_time: null,
    };
  },
  props: [
    "type", // type文字列
    "label", // ラベル文字列
    "tip_html", // ツールチップテキスト(html)
    "is_notification", // 通知有無
  ],
  computed: {
    // 通知時刻（算出）
    notify_time: {
      get() {
        return this.inner_notify_time;
      },
      set(value) {
        this.inner_notify_time = value;

        // 通知時刻をIndexedDBに保存
        this.put_notify_time_to_db(value);
      },
    },
  },
  methods: {
    // 通知時刻クリック
    on_click: async function () {
      this.$root.focus_out();
      let res = await this.$refs.time_picker.show(
        this.notify_time,
        this.label + "通知時刻",
        "",
        true
      );
      this.notify_time = res;
    },
    // 通知時刻をIndexedDB/push_list.dbに保存
    put_notify_time_to_db: async function (value) {
      let notif_time = new Object();
      if (value != null) {
        notif_time.HH = dayjs(value, "HH:mm").get("hour");
        notif_time.mm = dayjs(value, "HH:mm").get("minute");
      } else {
        // マイナス値は通知なしとみなす
        notif_time.HH = -1;
        notif_time.mm = -1;
      }

      // IndexedDBに保存
      await idb.notifications
        .where("name")
        .equals(this.type)
        // データ挿入(Promiseが返る)
        .modify({
          HH: notif_time.HH,
          mm: notif_time.mm,
        })
        // エラー処理
        .catch((error) => {
          console.error(error);
        });

      //push_list.dbにも保存
      let notification = await idb.notifications
        .where("name")
        .equals(this.type)
        .first();

      const request = {
        userPublicKey: (await idb.globals.get("userPublicKey")).value, // 通知ONの後にここに来るので、エラーにはならない想定
        type: notification.id, // typeに相当するインデックスを送る
        HH: get_utc_hour(notif_time.HH, notif_time.mm),
        mm: get_utc_min(notif_time.HH, notif_time.mm),
      };

      // SQLite
      const response = await fetch("update_notify_time.php", {
        // 第1引数に送り先
        method: "POST", // メソッド指定
        headers: { "Content-Type": "application/json" }, // jsonを指定
        body: JSON.stringify(request), // json形式に変換して添付
      }).catch((error) => {
        console.log(error); // エラー表示
      });
      // TODO: promise成功まで確認
      await response.json().catch((error) => {
        console.log(error); // エラー表示
      });
    },
  },
  created: async function () {
    // 通知時刻（内部変数）をIndexedDBより初期化
    const notification = await idb.notifications
      .where("name")
      .equals(this.type)
      .first()
      .catch((error) => {
        console.error(error);
      });

    // データ処理
    if (notification != null) {
      if (notification.HH >= 0) {
        this.inner_notify_time =
          ("00" + notification.HH).slice(-2) +
          ":" +
          ("00" + notification.mm).slice(-2);
      } else {
        // マイナス値は通知なしとみなす
        this.inner_notify_time = null;
      }
    }
  },
});
