// idb.js
import Dexie from "dexie";

export const idb = new Dexie("myDatabase");
idb.version(1).stores({
  notifications: "id, name", // HH, mm, label, message",
  globals: "key", // value: 任意の単一値
});

export async function idb_delete() {
  await Dexie.delete("myDatabase");
}
