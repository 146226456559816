var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/img/144x144.png"),"width":"144","height":"144"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-img',{attrs:{"contain":"","width":"320","height":"50","src":require("@/assets/img/logo.png")}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text-body-1"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('help.main'))}})])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("help.about_dream_diary_title"))+" ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('help.about_dream_diary'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('help.how_to_use'))}})])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("help.ref_title"))+" ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('help.ref'))}})])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("help.inquiry"))+" ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('v-select',{attrs:{"items":[
              _vm.$t('help.inquiry_type_question'),
              _vm.$t('help.inquiry_type_demand'),
              _vm.$t('help.inquiry_type_defect'),
              _vm.$t('help.inquiry_type_others'),
            ],"label":_vm.$t('help.inquiry_type'),"outlined":""},model:{value:(_vm.inquiry_type),callback:function ($$v) {_vm.inquiry_type=$$v},expression:"inquiry_type"}}),_c('v-textarea',{attrs:{"counter":"500","maxlength":"500","color":"warning","label":_vm.$t('page.body'),"auto-grow":"","rows":"4","outlined":""},model:{value:(_vm.inquiry_body),callback:function ($$v) {_vm.inquiry_body=$$v},expression:"inquiry_body"}})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'mailto:contact@beverlyparksoft.com?subject=Dream Diary: ' +
                  _vm.inquiry_type +
                  '&body=' +
                  _vm.inquiry_body +
                  '%0D%0A%0D%0A----------------%0D%0AUserAgent:' +
                  _vm.user_agent,"target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email")]),_vm._v(_vm._s(_vm.$t("help.send")))],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-justify"},[_vm._v(" Icons made by "),_c('a',{attrs:{"href":"http://www.freepik.com","title":"Freepik"}},[_vm._v("Freepik")]),_vm._v(" from "),_c('a',{attrs:{"href":"https://www.flaticon.com/","title":"Flaticon"}},[_vm._v("www.flaticon.com")]),_vm._v(" is licensed by "),_c('a',{attrs:{"href":"http://creativecommons.org/licenses/by/3.0/","title":"Creative Commons BY 3.0","target":"_blank"}},[_vm._v("CC 3.0 BY")])])])],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-justify"},[_vm._v(" © "+_vm._s(_vm.this_year)+" "),_c('a',{attrs:{"href":"../index.html"}},[_vm._v("Beverly Park")]),_c('br'),_c('a',{attrs:{"href":"http://beverlyparksoft.com/privacy.html"}},[_vm._v(_vm._s(_vm.$t("help.privacy")))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }