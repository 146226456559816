
import Vue from "vue";

export default Vue.extend({
  name: "CustomTimePicker",

  data: function () {
    return {
      is_visible: false, // 表示フラグ
      caption: "", // キャプション
      message: "", // メッセージ
      is_cancel: false, // キャンセルボタンフラグ
      ok_label: "OK", // OKボタンラベル
      cancel_label: "通知しない", // キャンセルボタンラベル
      resolve: null,
      reject: null,
      time: null,
    };
  },
  computed: {
    // v-card-text がなぜか改行を受け付けないので、ここで\nを<br>に変換して渡す
    message_br: function () {
      if (this.message)
        // TODO: エラーの場合、message.messageにしないとダメ…？
        return this.message.replace(/\n/g, "<br>");
      else return null;
    },
  },
  methods: {
    // OKボタン押下
    on_ok: function () {
      this.resolve(this.time);
      this.is_visible = false;
    },
    // Cancelボタン押下
    on_cancel: function () {
      this.resolve(null);
      this.is_visible = false;
    },
    // 表示
    // i18nにて文字列を変数として扱う : https://github.com/kazupon/vue-i18n/issues/149
    show: function (time, caption, message, is_cancel, ok_label, cancel_label) {
      this.time = time;

      this.is_visible = true;

      this.caption = caption;
      this.message = message;
      this.is_cancel = is_cancel;

      if (ok_label) this.ok_label = ok_label;
      if (cancel_label) this.cancel_label = cancel_label;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
  },
});
