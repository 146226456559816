// Wrapper module of Google Identity Services
// References:
//  https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#gapi-callback
//  https://developers.google.com/identity/oauth2/web/guides/use-token-model
//  https://developers.google.com/identity/oauth2/web/reference/js-reference

import config from "../const/const";

let prev_mail;

// ログイン
// 成功したらユーザープロフィールの結果 resp.result を返す
/*
https://apis.google.com/js/api.js
 https://accounts.google.com/gsi/client
  gapi.load
   CB: gapi.client.init
         gapi.client.load ※もっと後でもいいはず
          ※gapiとgsiが揃ったらこの先を実行
          google.accounts.oauth2.initTokenClient
           CB: gapi.client.people.people
          tokenClient.requestAccessToken -> prompt
*/

// ログイン直列版：
// TODO: index.htmlの中身と重複
export const Login = () =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line
    gapi.load("client", () => {
      console.log("gapi.client.init");

      // eslint-disable-next-line
      gapi.client
        .init({
          apiKey: config.API_KEY,
          discoveryDocs: config.DISCOVERY_DOCS,
        })
        .then(function () {
          // eslint-disable-next-line
          gapi.client.load("sheets", "v4");
        })
        .then(
          async function (response) {
            console.log("gapi.client.load() done");
            console.log("response:" + response);
            console.log("initTokenClient()");

            // eslint-disable-next-line
            let tokenClient = google.accounts.oauth2.initTokenClient({
              client_id: config.CLIENT_ID,
              scope: config.SCOPES,
              prompt: "",
              callback: (tokenResponse) => {
                console.log("tokenResponse:" + tokenResponse);

                // eslint-disable-next-line
                gapi.client.people.people
                  .get({
                    resourceName: "people/me",
                    personFields: "emailAddresses,names,photos",
                  })
                  .then(async (resp) => {
                    if (resp.result.emailAddresses) {
                      localStorage.setItem(
                        "mail",
                        resp.result.emailAddresses[0].value
                      );
                      resolve(resp.result);
                    } else {
                      reject("no address returned.");
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              },
            });
            // Re-entrant function to request user consent.
            // Returns an access token to the callback specified in google.accounts.oauth2.initTokenClient
            // Use a user gesture to call this function and obtain a new, valid access token
            // when the previous token expires and a 401 status code is returned by Google API calls.

            if (prev_mail != undefined) {
              prev_mail = prev_mail.value;
            } else {
              prev_mail = null;
            }
            tokenClient.requestAccessToken({
              prompt: "",
              hint: prev_mail,
            });
          },
          function (reason) {
            console.log("Error: " + reason);
            reject(reason);
          }
        );
    });
  });

// ログアウト
// トークンを取り消す
export async function Logout() {
  // eslint-disable-next-line
  const token = gapi.client.getToken();
  if (token !== null) {
    // eslint-disable-next-line
    google.accounts.oauth2.revoke(token.access_token);

    // eslint-disable-next-line
    gapi.client.setToken("");
    localStorage.setItem("mail", "");
  }
}

// Sheetを取得
export function GetSheet(sheet_id, sheet_name) {
  return new Promise((resolve, reject) => {
    try {
      // gapiはawait/catchがなぜかエラーになるのでtry/catchして改めてPromiseでラップ
      // eslint-disable-next-line
      gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: sheet_id,
          range: sheet_name,
        })
        .then((res) => {
          resolve(res);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// Sheetを更新
export function UpdateSheet(sheet_id, sheet_name, values) {
  return new Promise((resolve, reject) => {
    try {
      // gapiはawait/catchがなぜかエラーになるのでtry/catchして改めてPromiseでラップ
      // eslint-disable-next-line
      gapi.client.sheets.spreadsheets.values
        .update({
          spreadsheetId: sheet_id,
          range: sheet_name,
          valueInputOption: "RAW",

          resource: {
            values: values,
          },
        })
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// SheetのIDを名前で検索して取得
export function GetSheetId(sheet_name) {
  return new Promise((resolve, reject) => {
    try {
      // gapiはawait/catchがなぜかエラーになるのでtry/catchして改めてPromiseでラップ
      // eslint-disable-next-line
      gapi.client.drive.files
        .list({
          q: 'name = "' + sheet_name + '" and trashed=false',
          pageSize: 1,
          fields: "nextPageToken, files(id, name)",
        })
        .then((response) => {
          // Sheet存在確認実施
          if (response) {
            let files = response.result.files;
            // 存在したらそこからファイルIDを取得:TODO ループ？
            if (files && files.length > 0) {
              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                resolve(file.id);
              }
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        });
    } catch (error) {
      reject(error);
    }
  });
}

// フォルダのIDを名前で検索して取得, なければ作成
export function GetFolderId(folder_name) {
  return new Promise((resolve, reject) => {
    try {
      // gapiはawait/catchがなぜかエラーになるのでtry/catchして改めてPromiseでラップ
      // eslint-disable-next-line
      gapi.client.drive.files
        .list({
          q: 'name = "' + folder_name + '" and trashed=false',
          pageSize: 1,
          fields: "nextPageToken, files(id, name)",
        })
        .then((response) => {
          // フォルダ存在確認実施
          if (response) {
            let files = response.result.files;
            // 存在したらそこからファイルIDを取得:TODO ループ？
            if (files && files.length > 0) {
              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                resolve(file.id);
              }
            } else {
              // フォルダ作成
              const fileMetadata = {
                name: folder_name, //作成したいフォルダの名前
                mimeType: "application/vnd.google-apps.folder",
              };
              const params = {
                resource: fileMetadata,
                fields: "id",
              };
              // eslint-disable-next-line
              gapi.client.drive.files.create(params).then((res) => {
                console.log("gapi.client.drive.files.create:" + res.result.id);

                resolve(res.result.id);
              });
            }
          } else {
            resolve(null);
          }
        });
    } catch (error) {
      reject(error);
    }
  });
}

// Sheetの新規作成
export function CreateSheet(folder_name, sheet_file_name, sheet_name) {
  return new Promise((resolve, reject) => {
    try {
      // gapiはawait/catchがなぜかエラーになるのでtry/catchして改めてPromiseでラップ

      GetFolderId(folder_name).then((folder_id) => {
        // シートファイル作成
        // eslint-disable-next-line
        gapi.client.sheets.spreadsheets
          .create({
            properties: {
              title: sheet_file_name,
            },
            sheets: {
              properties: {
                title: sheet_name,
              },
            },
          })
          .then((response) => {
            // シートファイルを親フォルダに移動

            const targetFileId = response.result.spreadsheetId; //移動したいファイルID
            const targetFolderId = folder_id; //移動先のフォルダID

            const params = {
              fileId: targetFileId,
              addParents: targetFolderId,
            };

            // eslint-disable-next-line
            gapi.client.drive.files
              .update(params)
              .then((res) => {
                console.log(res);
                resolve(response.result.spreadsheetId);
              })
              .catch((err) => console.log(err));
          });
      });
    } catch (error) {
      reject(error);
    }
  });
}
