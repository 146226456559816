
import Vue from "vue";
import CustomToolTip from "./CustomToolTip.vue";
import ToggleIcon from "./ToggleIcon.vue";

export default Vue.extend({
  name: "GeneralAttribute",

  components: {
    CustomToolTip,
    ToggleIcon,
  },

  data: function () {
    return {};
  },
  props: [
    "type", // 属性タイプ（text_field, text_area, ... )
    "label", // 表示属性ラベル : this.$i18n.t('xxx.xxx') などで国際化されている想定
    "tip_text", // ツールチップテキスト : this.$i18n.t('xxx.xxx') などで国際化されている想定
    "MAX_NUM_STRING", // 文字列タイプだった場合の最大文字数
    "rows", // テキストエリアの場合の初期行数
    "value", // 値
    "icon_type", // アイコンタイプ
    "icon_color", // アイコン色
  ],
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
});
