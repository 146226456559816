import Vue from "vue";
import Router from "vue-router";
import DreamMainView from "./components/DreamMainView.vue";

Vue.use(Router);

// コンポーネント再利用のため、ルーティング対象のコンポーネントはDrameMainViewに統一
export default new Router({
  routes: [
    {
      path: "/page/:date", // date: YYYY-MM-DD 形式
      component: DreamMainView,
      name: "page",
    },
    {
      path: "/list",
      component: DreamMainView,
      name: "list",
    },
    {
      path: "/help",
      component: DreamMainView,
      name: "help",
    },
    {
      path: "/settings",
      component: DreamMainView,
      name: "settings",
    },
  ],
});
