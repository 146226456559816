//konprogrammer.hatenablog.com/entry/2020/02/26/122607

// Object.freezeを使用しないと外部で値を書き換えることが出来る
// Object.freezeはネストしたオブジェクトまで見ないので、Objectの中でObjectを宣言する場合、宣言するObjectにもObject.freezeをつける
export default Object.freeze({
  CLIENT_ID:
    "808074379204-h72eddapr8ua048mmfds7jrlgj8b0hqt.apps.googleusercontent.com", // GCPのクライアントID
  API_KEY: "AIzaSyDTgWJLXLOE4r2K4I56XcJudwiIy2iSedM", // GCPのAPIキー
  DISCOVERY_DOCS: [
    // Discovery docs
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    "https://sheets.googleapis.com/$discovery/rest?version=v4",
    "https://people.googleapis.com/$discovery/rest?version=v1",
  ],
  SCOPES:
    "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile", // Scopes
});
