
import Vue from "vue";
import ToggleIcon from "./ToggleIcon.vue";
import SearchWorker from "worker-loader!@/workers/search";

export default Vue.extend({
  name: "DreamSearch",

  components: {
    ToggleIcon,
  },

  data: function () {
    return {
      items: [], // 日記アイテム
      num_items: 0, // 日記アイテム数
      search_text: null, // 検索テキスト
      num_searched: 0, // 検索結果数
      is_star: false, // スター検索フラグ
      is_lucid: false, // 明晰夢検索フラグ
      show_result: true, // 結果表示フラグ
    };
  },
  methods: {
    // リストから選択された日のページを表示（親のボタンナビゲーションコンポーネントにemit）
    show_page: function (index) {
      this.$emit("show_page", this.items[index].date);
    },
    // search_text を含むデータをリストアップ
    // nullなら全部
    list_up: function () {
      // 結果表示をOFFして検索中プログレス円を表示
      this.show_result = false;

      // alert("workers:" + navigator.hardwareConcurrency);
      const worker = new SearchWorker();

      console.log("worker:" + worker);

      worker.addEventListener(
        "message",
        (e) => {
          // 結果を受領して格納
          this.items = e.data.items;
          this.num_items = e.data.num_items;
          this.num_searched = e.data.num_searched;

          // 結果表示
          this.show_result = true;
        },
        false
      );

      // 引数をまとめてworkerに投げる
      let message = new Object();

      message.items = [];
      message.num_items = 0;
      message.num_searched = 0;
      message.all_data = this.$root.all_data;
      message.is_lucid = this.is_lucid;
      message.is_star = this.is_star;
      message.search_text = this.search_text;

      worker.postMessage(message);
    },
  },
});
