
import Vue from "vue";

export default Vue.extend({
  name: "CustomAlert",

  data: function () {
    return {
      is_visible: false, // 表示フラグ
      caption: "", // キャプション
      message: "", // メッセージ
      is_copy: false, // コピーボタンフラグ
      is_cancel: false, // キャンセルボタンフラグ
      ok_label: "OK", // OKボタンラベル
      cancel_label: "Cancel", // キャンセルボタンラベル
      copy_label: "Copy", // コピーボタンラベル
      resolve: null,
      reject: null,
    };
  },
  computed: {
    // v-card-text がなぜか改行を受け付けないので、ここで\nを<br>に変換して渡す
    message_br: function () {
      if (this.message)
        // TODO: エラーの場合、message.messageにしないとダメ…？
        return this.message.replace(/\n/g, "<br>");
      else return null;
    },
  },
  methods: {
    // OKボタン押下
    on_ok: function () {
      this.resolve(true);
      this.is_visible = false;
    },
    // Cancelボタン押下
    on_cancel: function () {
      this.resolve(false);
      this.is_visible = false;
    },
    // 表示
    // i18nにて文字列を変数として扱う : https://github.com/kazupon/vue-i18n/issues/149
    show: function (
      caption,
      message,
      is_cancel,
      is_copy,
      ok_label,
      cancel_label,
      copy_label
    ) {
      this.is_visible = true;

      this.caption = caption;
      this.message = message;
      this.is_cancel = is_cancel;
      this.is_copy = is_copy;

      if (ok_label) this.ok_label = ok_label;
      if (cancel_label) this.cancel_label = cancel_label;
      if (copy_label) this.copy_label = copy_label;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // メッセージをクリップボードにコピー
    // https://qiita.com/simiraaaa/items/2e7478d72f365aa48356
    copy_to_cb: function () {
      let string = this.message;

      let temp = document.createElement("div");

      temp.appendChild(document.createElement("pre")).textContent = string;

      let s = temp.style;
      s.position = "fixed";
      s.left = "-100%";

      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);

      let result = document.execCommand("copy");

      document.body.removeChild(temp);

      // true なら実行できている falseなら失敗か対応していないか
      if (result) {
        this.show(
          this.$i18n.t("common.complete"),
          this.$i18n.t("common.copy_success")
        );
      } else {
        this.show(
          this.$i18n.t("common.error"),
          this.$i18n.t("common.copy_fail")
        );
      }
      return result;
    },
  },
});
