
import Vue from "vue";
import CustomToolTip from "./CustomToolTip.vue";
import DreamNotification from "./DreamNotification.vue";
import dayjs from "dayjs";
import { get_utc_hour, get_utc_min } from "../plugins/dayjs_util.js";
import { idb, idb_delete } from "../plugins/idb";
import { Login, Logout } from "../plugins/gis";

export default Vue.extend({
  name: "DreamSettings",

  components: {
    CustomToolTip,
    DreamNotification,
  },

  data: function () {
    return {
      langs: [
        // 言語
        { locale: "en" },
        { locale: "ja" },
      ],
      is_notification: null, // 通知フラグ
      notify_attribs: null, // 通知属性配列
    };
  },
  computed: {},
  methods: {
    // ログイン
    log_in: async function () {
      let login_result = await Login();
      if (login_result != undefined && login_result.names != null) {
        // this.$root.log_in(login_result);
        // ログインが確認できたら一旦リロードして最初から
        location.reload();
      } else {
        alert("ログインできませんでした。");
      }
    },
    // ログアウト
    log_out: async function () {
      await Logout();
      this.$root.log_out();

      // idbの中身を削除
      await idb_delete();

      // ログアウトが確認できたら一旦リロードして最初から
      location.reload();
    },
    // ブラウザの言語を取得
    // from https://qiita.com/shogo82148/items/548a6c9904eb19269f8c
    getBrowserLanguage: function () {
      try {
        let language =
          (window.navigator.languages && window.navigator.languages[0]) ||
          window.navigator.language ||
          window.navigator.userLanguage ||
          window.navigator.browserLanguage;
        return language.substr(0, 2);
      } catch (e) {
        return undefined;
      }
    },
    // ロケール設定
    set_locale: async function (locale) {
      await idb.globals.put({ key: "locale", value: locale });
      this.$i18n.locale = locale;
      dayjs.locale(locale);

      // 属性タイプ配列をIDBに格納
      for (let i = 0; i < this.notify_attribs.length; i++) {
        idb.notifications
          .update(i, {
            name: this.notify_attribs[i].type,
            label: this.$i18n.t(this.notify_attribs[i].label),
            message: this.$i18n.t(this.notify_attribs[i].message),
          })
          // エラー処理
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // 通知フラグ更新
    update_is_notification: function () {
      if ("Notification" in window) {
        let permission = Notification.permission;

        if (permission === "denied" || permission === "default") {
          this.is_notification = null;
        } else if (permission === "granted") {
          this.is_notification = "true";
        }
      }
    },
    // WebPushを許可する仕組み
    allow_web_push: async function () {
      let is_allow = this.is_notification;

      // 通知の許可
      if (is_allow == "true") {
        if ("Notification" in window) {
          const permission = Notification.permission;

          if (permission === "denied") {
            alert(
              "Push通知が拒否されているようです。ブラウザの設定からPush通知を有効化してください"
            );
            Vue.nextTick(() => {
              this.update_is_notification();
            });
          } else if (permission === "granted") {
            // 既に許可済みの場合は特に何もしない
          } else {
            // default
            const permission = await Notification.requestPermission();

            if (permission == "granted") {
              // 許可
            } else if (permission == "denied") {
              // 拒否
              alert("Push通知が拒否されました。");
              Vue.nextTick(() => {
                this.update_is_notification();
              });
            } else if (permission == "default") {
              // 無視
              alert("Push通知が許可されませんでした。");
              Vue.nextTick(() => {
                this.update_is_notification();
              });
            }
          }
        }
      }

      // 通知リスト(DB)への追加 or 削除

      // 取得したPublicKey
      const appServerKey =
        "BIb4ZJy-D7QvAzEP_IRrrpiDW29v2PyTKI_i0b979KQuEI8RNJC8QxYdmB5QUsYX5-lGgC0zncyG_n8_Yfe5eQA";
      const applicationServerKey = this.urlB64ToUint8Array(appServerKey);

      // Use serviceWorker.ready to ensure that you can subscribe for push : https://developer.mozilla.org/ja/docs/Web/API/PushManager/subscribe

      const serviceWorkerRegistration =
        await navigator.serviceWorker.ready.catch((err) => {
          console.log(err);
        });

      if (serviceWorkerRegistration) {
        console.log("service worker ready");
        let options = {
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        };

        // 強制更新（デバッグ用）
        serviceWorkerRegistration.update();

        // 購読開始
        const pushSubscription = await serviceWorkerRegistration.pushManager
          .subscribe(options)
          .catch((err) => {
            console.log(err);
          });

        if (pushSubscription) {
          console.log(pushSubscription.endpoint);

          // 必要なトークンを変換して取得（これが重要！！！）: https://zenn.dev/nnahito/articles/fd2c8b0ad0d19a
          const key = pushSubscription.getKey("p256dh");
          const token = pushSubscription.getKey("auth");

          // 現在の通知時刻も改めて含んでおく
          let notifications = new Array(this.notify_attribs.length);
          for (let i = 0; i < this.notify_attribs.length; i++) {
            notifications[i] = await idb.notifications
              .where("name")
              .equals(this.notify_attribs[i].type)
              .first();

            if (notifications[i] == undefined) {
              notifications[i] = new Object();
              notifications[i].HH = -1;
              notifications[i].mm = -1;
            }
          }

          const request = {
            endpoint: pushSubscription.endpoint,
            userPublicKey: btoa(
              String.fromCharCode.apply(null, new Uint8Array(key))
            ),
            userAuthToken: btoa(
              String.fromCharCode.apply(null, new Uint8Array(token))
            ),
            is_allow: is_allow, // 許可 or 不許可,
            notifyHH0: get_utc_hour(notifications[0].HH, notifications[0].mm),
            notifymm0: get_utc_min(notifications[0].HH, notifications[0].mm),
            notifyHH1: get_utc_hour(notifications[1].HH, notifications[1].mm),
            notifymm1: get_utc_min(notifications[1].HH, notifications[1].mm),
          };

          await idb.globals.put({
            key: "endpoint",
            value: pushSubscription.endpoint,
          });
          await idb.globals.put({
            key: "userPublicKey",
            value: btoa(String.fromCharCode.apply(null, new Uint8Array(key))),
          });
          await idb.globals.put({
            key: "userAuthToken",
            value: btoa(String.fromCharCode.apply(null, new Uint8Array(token))),
          });

          console.log(request);

          // SQLite
          const response = await fetch("store_push_info.php", {
            // 第1引数に送り先
            method: "POST", // メソッド指定
            headers: { "Content-Type": "application/json" }, // jsonを指定
            body: JSON.stringify(request), // json形式に変換して添付
          }).catch((error) => {
            console.log(error); // エラー表示
          });
          // TODO: promise成功まで確認
          if (response) {
            const res = await response.json().catch((error) => {
              console.log(error); // エラー表示
            });

            if (res) {
              console.log(res); // やりたい処理
            }
          }
        }
      }
    },
    // 強制リロード（デバッグ）
    reload: async function () {
      // 今はSWの強制 unregister
      const registrations = await window.navigator.serviceWorker
        .getRegistrations()
        .catch(function (error) {
          console.log("unregister failed:" + error);
        });

      if (registrations) {
        for (let registration of registrations) {
          await registration.unregister();

          window.location.reload(true);
        }
      }
    },
    /**
     * トークンを変換するときに使うロジック
     * @param {*} base64String
     */
    urlB64ToUint8Array: function (base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
  created: async function () {
    // 通知属性クラス
    let NotifyAttribs = function (type, label, tip_html, message) {
      this.type = type;
      this.label = label;
      this.tip_html = tip_html;
      this.message = message;
    };

    // 通知属性初期化
    this.notify_attribs = [];

    this.notify_attribs.push(
      new NotifyAttribs(
        "induction",
        "settings.notification.induction",
        "settings.notification.induction_tip",
        "settings.notification.induction_msg"
      )
    );
    this.notify_attribs.push(
      new NotifyAttribs(
        "daily",
        "settings.notification.daily",
        "settings.notification.daily_tip",
        "settings.notification.daily_msg"
      )
    );

    // 通知フラグ更新
    this.update_is_notification();

    // IDB初期化
    for (let i = 0; i < this.notify_attribs.length; i++) {
      // ストアが存在していなかったら追加
      if (
        (await idb.notifications.get(i).catch(() => undefined)) == undefined
      ) {
        await idb.notifications.add({ id: i });
      }
    }

    // ロケール初期化
    let locale = await idb.globals.get("locale");

    if (locale != null) {
      locale = locale.value;
    }
    // DBになければブラウザから自動取得
    else {
      locale = this.getBrowserLanguage();

      if (locale != "ja") {
        locale = "en";
      }
    }

    this.set_locale(locale);
  },
});
