
import Vue from "vue";

export default Vue.extend({
  name: "DreamMain",

  data() {
    return {
      bottomNav: "page", // ボトムナビ内の選択値
      innerHeighitInit: 0, // window.innerHeight の初期値
      show_footer: true, // フッタの表示状態
    };
  },
  methods: {
    // クリック時の挙動
    on_click: function (contents_name: string) {
      if (contents_name == "page") {
        this.$refs.router_view.show_page();
      } else {
        this.$router.push({ path: "/" + contents_name }).catch((err) => {
          console.log(err);
        });
      }
      // パスコードロック
      //    this.$root.$refs.passcode.show();

      // リセットスクロール
      this.reset_scroll();
    },
    // リセットスクロール : TODO : パッと切り替わると今のページの頭がパッと出るのも気になる...
    reset_scroll: function () {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
    init_banner: function () {
      // フッタバナー ローテーションタイマー
      //				setInterval("reload_iframe('footer_banner');", 30000);
    },
  },
  // 監視
  watch: {
    // ルートの変更に伴うボトムナビ表示更新
    $route: function (to, from) {
      this.bottomNav = to.name;
    },
  },
  created: function () {
    // 認証成功されている場合はログイン状態とする
    if (
      window.g_login_result != undefined &&
      window.g_login_result.names != null
    ) {
      this.$root.log_in(window.g_login_result);
    }

    // モバイルでソフトキーボード表示時にフッタを消すための処理
    // innerHeightの初期値を取得
    this.innerHeighitInit = window.innerHeight;

    // リサイズイベント発生時に、現在のinnerHeightが初期値より0.9倍未満であればフッタを消す
    // TODO: PCは除外
    window.onresize = () => {
      if (window.innerHeight < this.innerHeighitInit * 0.9) {
        this.show_footer = false;
      } else {
        this.show_footer = true;
      }
    };

    // Page初期表示
    this.$router.push({ path: "/page/0" }).catch((err) => {
      console.log(err);
    });
  },
  mounted: function () {
    console.log("DreamMain::mounted");
    this.init_banner();
    setTimeout(() => {
      this.$root.show_footer_first_time = true;
    }, 3000); // TODO: 初回表示時はなぜかv-footerが隠れるのでとりあえずハック

    // これではダメだった…ここでブレークを貼ってもレンダリングされてない…？
    this.$nextTick(function () {
      //     let i = 0;
    });
  },
});
