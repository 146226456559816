import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import router from "./router";

//uncle-javascript.com/vue-new-settings
Vue.config.productionTip = true;

new Vue({
  vuetify,
  i18n,
  router,
  render: (h) => h(App),
  data: {
    all_data: [], // 全日記データ
    is_login: false, // 認証状態
    display_name: "", // ユーザー表示名
    display_photos: "", // ユーザー表示アイコン
    is_first_page_mounted: false, // pageの初回マウントフラグ
    show_footer_first_time: false, // フッタの初回表示制御用のハックフラグ
    server_time_stamp: -1, // サーバータイムスタンプ: -1 同期未確認, 0 非同期, >0, 同期
    version: process.env.VUE_APP_BUILD_TIME, // バージョン文字列
    is_online: navigator.onLine, // オンラインフラグ
  },
  // 生成イベント
  created: async function () {
    // is_online フラグの更新ハンドラ
    window.addEventListener("online", () => {
      this.is_online = true;
    });
    window.addEventListener("offline", () => {
      this.is_online = false;
    });
  },
  methods: {
    // フォーカスアウトしてソフトキーボードを隠す
    // 結果的にchangeも呼ばれるはず
    focus_out: function () {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    },
    // ログイン
    log_in: function (login_result: any) {
      this.is_login = true;

      // ユーザープロファイルを設定
      this.display_name = login_result.names[0].displayName;
      this.display_photos = login_result.photos[0].url;
    },
    // ログアウト
    log_out: function () {
      this.is_login = false;

      // ユーザープロファイルをリセット
      this.display_name = "";
      this.display_photos = "";
    },
  },
}).$mount("#app");
