
import Vue from "vue";

export default Vue.extend({
  name: "DreamHelp",

  data: function () {
    return {
      inquiry_body: null,
      inquiry_type: this.$t("help.inquiry_type_question"),
      user_agent: navigator.userAgent,
    };
  },
  computed: {
    // 今年
    this_year: function () {
      return new Date().getFullYear();
    },
  },
});
