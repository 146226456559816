
import Vue from "vue";
import DreamTop from "./components/DreamTop.vue";

export default Vue.extend({
  name: "App",

  components: {
    DreamTop,
  },
});
