
import Vue from "vue";
import GeneralAttribute from "./GeneralAttribute.vue";
import CustomAlert from "./CustomAlert.vue";
import dayjs from "dayjs";
import { idb } from "../plugins/idb";
import store from "../store/store";
import { GetSheet, UpdateSheet, GetSheetId, CreateSheet } from "../plugins/gis";

export default Vue.extend({
  name: "DreamPage",

  components: {
    GeneralAttribute,
    CustomAlert,
  },

  data: () => {
    return {
      date: "", // 日付
      nextdate: "", // 次のデータのある日付
      prevdate: "", // 前のデータのある日付
      arrayEvents: [], // 過去に日記のある日
      date_menu: false, // デートピッカーメニューの可視性制御（Cancel用）
      showPage: false, // ページの表示フラグ・待機中に見えなくするため
      is_editing: false, // 編集中
      is_saving: false, // 保存中
      auto_save_handler_id: 0, // オートセーブ用のsetIntervalのID
      attributes: [], // 属性配列
      pickerDate: null, // 月変更監視用
      spread_sheet_id: null, // スプレッドシートID変数
      SPREAD_SHEET_FOLDER_NAME: ".dream.beverlyparksoft.com", // スプレッドシートフォルダ名定数
      SPREAD_SHEET_FILE_NAME: ".dream_diary_save_data", // スプレッドシートファイル名定数
      SPREAD_SHEET_NAME: "SHEET0", // スプレッドシート名定数
      save_button_bottom_style: "bottom: 120px", // 保存ボタンのbottom初期値
    };
  },
  props: [
    "show_footer", // フッタの表示状態
  ],
  // 監視
  watch: {
    // https://www.366service.com/jp/qa/228e923386c25aad11223f223798e321
    pickerDate(newval, oldval) {
      // here you can check if month changed using newval and oldval
      console.log("[newval, oldval]= [" + newval + "," + oldval + "]");
      // 初回は@clickに任せる
      if (oldval != null) {
        this.update_array_events(newval);
      }
    },
    // フッタの表示状況に合わせて保存系ボタンの位置を調整
    show_footer: function (newval, oldval) {
      if (newval) {
        this.save_button_bottom_style = "bottom: 120px";
      } else {
        this.save_button_bottom_style = "bottom: 20px";
      }
    },
    // Page内のルートの変更に伴う日付別表示
    $route: function (to, from) {
      // ページ表示時は特別処理
      if (this.$route.name == "page") {
        // dateが0 (初回表示 TODO:もう少しきれいにならないか)
        // -> this.date をそのまま使って表示
        if (this.$route.params.date == 0) {
          this.show_day_page();
        }
        // dateが0でなく、しかも現在値と一致 (アプリからのpush経由)
        // -> すでに表示済なので何もしない
        else if (this.$route.params.date == this.date) {
        }
        // それ以外（dateが0でなく、現在値と異なる == ブラウザ操作経由)
        // -> dateを更新して表示
        else {
          this.date = this.$route.params.date;
          this.show_day_page();
        }
      }
    },
  },
  // 算出プロパティ
  computed: {
    // 曜日付き日付表記
    dreamdate_ex: function () {
      return this.get_date_with_day();
    },
    // この日付のデータのインデックス
    day_element_idx: function () {
      return this.getDayElementIdxByDate(this.$root.all_data, this.date);
    },
  },
  // 生成イベント
  created: async function () {
    // 仮:attributes の初期化 TODO : もっとスマートなやり方はないのか…？

    // 汎用日記属性クラス
    // https://www.yunabe.jp/docs/javascript_class_in_google.html
    // 定義方法 : let alice = new Person('Alice', 7);

    // クラスとコンストラクタは関数を使って定義します
    let DiaryAttribs = function (
      index,
      id,
      type,
      label_str_id,
      tip_text_str_id,
      MAX_NUM_STRING,
      default_rows,
      icon_type,
      icon_color
    ) {
      // this はインスタンスを表します。
      this.index = index;
      this.id = id;
      this.type = type;
      this.label_str_id = label_str_id;
      this.tip_text_str_id = tip_text_str_id;
      this.MAX_NUM_STRING = MAX_NUM_STRING;
      this.default_rows = default_rows; // テキストエリアの初期行数
      this.value = "";
      this.icon_type = icon_type;
      this.icon_color = icon_color;
    };

    // 属性初期設定

    this.attributes.push(
      new DiaryAttribs(
        1,
        "body",
        "text_area",
        "page.body",
        "page.body_tip",
        2048,
        4
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        2,
        "title",
        "text_field",
        "page.title",
        "page.title_tip",
        32
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        3,
        "star",
        "toggle_icon",
        "page.star",
        "page.star_tip",
        4,
        0,
        "mdi-star",
        "yellow"
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        4,
        "lucid",
        "toggle_icon",
        "page.lucid",
        "page.lucid_tip",
        4,
        0,
        "mdi-eye",
        "blue"
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        5,
        "after",
        "text_area",
        "page.after",
        "page.after_tip",
        256,
        1
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        6,
        "before",
        "text_area",
        "page.before",
        "page.before_tip",
        256,
        1
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        7,
        "introduction",
        "text_area",
        "page.introduction",
        "page.introduction_tip",
        256,
        1
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        8,
        "reality",
        "text_area",
        "page.reality",
        "page.reality_tip",
        512,
        1
      )
    );
    this.attributes.push(
      new DiaryAttribs(
        9,
        "prophetic_dream",
        "text_area",
        "page.prophetic_dream",
        "page.prophetic_dream_tip",
        512,
        1
      )
    );
    // データをロードして初期化
    await this.load_and_init_all_data();

    // アンロード前の警告
    window.addEventListener("beforeunload", (event) => {
      if (this.is_editing) {
        // https://developer.mozilla.org/ja/docs/Web/Reference/Events/beforeunload
        let confirmationMessage = "Do you really quit without saving?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
  },
  mounted: function () {
    this.$root.is_first_page_mounted = true;
  },
  // 破棄直前イベント
  beforeDestroy() {
    // TODO: おそらく呼ばれない...
    this.save_edited();
  },
  methods: {
    // 曜日付き日付を取得
    get_date_with_day: function () {
      return dayjs(new Date(this.date)).format("YYYY-MM-DD (ddd)");
    },
    // 中身が全て空かどうかの確認
    is_empty: function () {
      // トグル以外のタイプのvalueを見て、1つでも中身が入っていたらfalseを返す
      let is_empty_flag = true;
      this.attributes.forEach((attr) => {
        if (attr.type != "toggle_icon") {
          if (attr.value != "") {
            is_empty_flag = false;
          }
        }
      });

      return is_empty_flag;
    },
    // 最新の編集内容を保存
    save_edited: function (keep_editing) {
      console.log("save_edited");

      // 編集中は一旦解除してフッタも戻す
      if (this.is_editing) {
        if (keep_editing != true)
          // keep_editing時除く
          this.set_is_editing(false);
      }

      // どれかのテキストに中身がある時のみ保存
      if (!this.is_empty()) {
        // 保存するデータをここで固定
        // set()中に非同期で別のページが表示されたりすると保存データが書き換わってしまうため
        let save_data = ["", "", "", "", "", "", "", "", ""]; // nullだとupdateでは上書かれなくなる

        // 日付だけは特別扱い
        save_data[0] = this.date;

        this.attributes.forEach((attr) => {
          // トグルアイコン以外は、存在がなければnullのまま
          if (attr.type != "toggle_icon") {
            if (attr.value) {
              save_data[attr.index] = attr.value;
            }
          }
          // トグルアイコン時はtrue以外はnull
          else {
            if (attr.value == true) {
              save_data[attr.index] = attr.value;
            }
          }
        });

        // 保存
        if (this.day_element_idx >= 0) {
          // 上書き
          this.attributes.forEach((attrib) => {
            this.$root.all_data[this.day_element_idx][attrib.index] =
              save_data[attrib.index];
          });
        } else {
          // 追加
          this.$root.all_data.push(save_data);
        }

        //保存
        this.save_all_data();
      }
    },
    // データをセーブ(ローカルとクラウド両方)
    save_all_data: async function () {
      // 保存中は保存不可
      if (this.is_saving) {
        return;
      } else {
        this.is_saving = true;
      }

      // ソート
      this.$root.all_data.sort();

      // ローカル保存
      await idb.globals.put({ key: "all_data", value: this.$root.all_data });

      // オンラインかつ認証中ならサーバ保存
      if (this.$root.is_online && this.$root.is_login) {
        // タイムスタンプ取得
        let response = await GetSheet(
          this.spread_sheet_id,
          this.SPREAD_SHEET_NAME
        ).catch(async (error) => {
          alert("タイムスタンプ取得失敗" + error);
          await this.UpdateTimeStampOnLocal(null);
          response = null;
          console.log("response==null");
        });
        console.log("GetSheet() done");

        // タイムスタンプ取得成功時
        if (response) {
          let server_time_stamp;
          if (response.result.values != null) {
            server_time_stamp = Number(
              response.result.values[0][this.attributes.length + 1]
            );
          } else {
            server_time_stamp = 0;
          }

          let local_server_time_stamp = await idb.globals.get(
            "server_time_stamp"
          );
          if (local_server_time_stamp != null) {
            local_server_time_stamp = local_server_time_stamp.value;
          }

          let is_local_prior = true;

          // ローカルの記録がnull：未同期→選択
          // ローカル優先の聞き方
          if (local_server_time_stamp == null) {
            if (
              !confirm(
                "最新のローカル更新がGoogleドライブと未同期です。ローカルの更新をGoogleドライブに保存しますか？"
              )
            ) {
              is_local_prior = false;
            }
          }
          // サーバの方が新しい：別端末で更新→選択
          // サーバ優先の聞き方
          else if (local_server_time_stamp < server_time_stamp) {
            if (
              confirm(
                this.get_local_server_update_string(
                  local_server_time_stamp,
                  server_time_stamp
                ) +
                  "Googleドライブに新しい更新があります。ローカルの更新を破棄して、Googleドライブの更新をローカルに保存しますか？"
              )
            ) {
              is_local_prior = false;
            }
          }
          // サーバの方が古い：通常はあり得ないケース...一応選択
          // ローカル優先の効き方
          else if (local_server_time_stamp > server_time_stamp) {
            if (
              !confirm(
                this.get_local_server_update_string(
                  local_server_time_stamp,
                  server_time_stamp
                ) +
                  "Googleドライブに古い更新があります。ローカルの更新をGoogleドライブに保存しますか？"
              )
            ) {
              is_local_prior = false;
            }
          }
          // 同期：ローカルから更新

          // ローカルの最新をサーバに更新
          if (is_local_prior) {
            await this.save_all_data_to_cloud();
          }
          // サーバを優先する場合
          else {
            // TODO
            alert("未実装です");
          }
        }
        // タイムスタンプ取得失敗時
        else {
          alert("タイムスタンプ取得失敗" + response);
          await this.UpdateTimeStampOnLocal(null);
        }
      }
      // オフラインもしくは非認証中ならサーバタイムスタンプnull
      else {
        await this.UpdateTimeStampOnLocal(null);
      }

      // 保存終了
      this.is_saving = false;
    },
    // データをクラウドにセーブ
    save_all_data_to_cloud: async function () {
      //https://stackoverflow.com/questions/46049039/google-sheets-api-v4-values-update-syntax

      // 可読性のためヘッダを付与
      let header = [];
      header.push("date");
      this.attributes.forEach((attr) => {
        header.push(attr.id);
      });

      let current_time_stamp = Date.now();
      header.push(current_time_stamp); // タイムスタンプ付与
      let temp_all_data = this.$root.all_data.slice(); // 値渡し
      temp_all_data.unshift(header);
      temp_all_data.push(["", "", "", "", "", "", "", "", ""]); // 削除時の最終行を上書きするための空行ヲ追加

      let response = await UpdateSheet(
        this.spread_sheet_id,
        this.SPREAD_SHEET_NAME,
        temp_all_data
      ).catch(async (error) => {
        alert("Sheets保存失敗" + error);
        await this.UpdateTimeStampOnLocal(null);
        response = null;
        console.log("response==null");
      });
      console.log("UpdateSheet() done");

      if (response) {
        // 同期成功
        await this.UpdateTimeStampOnLocal(current_time_stamp);
      }
    },
    // 日記の削除
    delete_diary: async function () {
      if (
        await this.$refs.alert.show(
          this.$i18n.t("page.ask_delete"),
          this.dreamdate_ex + ":" + this.$i18n.t("page.confirm_delete"),
          true,
          false,
          this.$i18n.t("page.do_delete"),
          this.$i18n.t("page.cancel_delete")
        )
      ) {
        if (this.day_element_idx >= 0) {
          this.$root.all_data.splice(this.day_element_idx, 1);
        }

        // 保存
        this.save_all_data();

        // 別のページにジャンプ
        if (!this.go_prev_day()) {
          if (!this.go_next_day()) {
            this.show_today_page();
          }
        }
      }
    },
    // inputハンドラ
    on_input: function (str) {
      // TODO:strがboolの場合は予期しないタイミングで呼ばれるのでいったん除外
      if (str == true || str == false) {
        return;
      }

      // 編集中モードにする
      if (this.is_editing == false) {
        this.set_is_editing(true);
      }
    },
    // 編集開始・終了
    set_is_editing: function (flag) {
      if (flag) {
        this.is_editing = true;

        // オートセーブ開始 (60秒毎)
        /*
        this.auto_save_handler_id = setInterval(
          this.save_edited.bind(this),
          60000,
          true
        );
        */
      } else {
        this.is_editing = false;

        // オートセーブ終了
        //clearInterval(this.auto_save_handler_id);
      }
    },
    // 過去に日記のある日を特定（arrayEventsの更新）
    update_array_events: function (this_month) {
      // 一旦配列をクリア
      this.arrayEvents = [];
      this.is_lucids = [];
      this.is_star = [];

      // 初回は今月を入れる
      if (this_month == null) {
        this_month = dayjs(new Date(this.date)).format("YYYY-MM");
      }

      // ★データの一覧を取得してdateの配列を格納
      for (let i = 0; i < this.$root.all_data.length; i++) {
        let day_element = this.$root.all_data[i];
        let date = day_element[0];

        // 指定月のみ
        if (!day_element[0].indexOf(this_month)) {
          this.arrayEvents.push(day_element[0]);
          this.is_lucids[day_element[0]] = day_element[4]; // 明晰夢を見ていたら色を変える TODO マジックナンバー
          this.is_star[day_element[0]] = day_element[3]; // スター付きでも色を変える TODO マジックナンバー
        }
      }
    },
    // dateで明晰夢 and/or スター:非同期だとまともにreturnできなさそう
    get_event_color: function (date) {
      if (this.is_lucids[date] && this.is_star[date]) {
        return ["blue", "yellow"];
      } else if (this.is_lucids[date]) {
        return "blue";
      } else if (this.is_star[date]) {
        return "yellow";
      } else {
        return "white";
      }
    },
    // その日のデータを表示
    // date指定はオプション
    show_day_page: async function (date) {
      // date指定があれば更新
      if (date) {
        this.date = date;
      }

      // 指定ルートが現状と異なる場合はpush
      if (
        !(this.$route.name == "page" && this.$route.params.date == this.date)
      ) {
        this.$router.push({ path: "/page/" + this.date }).catch((err) => {
          console.log(err);
        });
      }

      // 一旦非表示
      this.showPage = false;

      // 今日のデータがある場合は取得
      if (this.day_element_idx >= 0) {
        // ページ遷移にフェード効果を付けるためあえてsetTimeout()する
        setTimeout(() => {
          // 汎用属性にて値取得
          this.attributes.forEach((attrib) => {
            attrib.value =
              this.$root.all_data[this.day_element_idx][attrib.index];
          });

          // 表示
          this.showPage = true;

          // 前後の日を確認 : 同期的に
          this.calc_prev_next_day();
        }, 100);
        // パスコードロック
        /*
        await this.$root.$refs.passcode.show();
        // 汎用属性にて値取得
        this.attributes.forEach((attrib) => {
          attrib.value =
            this.$root.all_data[this.day_element_idx][attrib.index];
        });
        */
      }
      // なければ空ページ
      else {
        // 汎用属性にて初期化
        this.attributes.forEach((attrib) => {
          attrib.value = "";
        });

        // 表示
        this.showPage = true;

        // 前後の日を確認 : 同期的に
        this.calc_prev_next_day();
      }
    },
    // 本日を表示
    show_today_page: function () {
      this.date = dayjs(new Date()).format("YYYY-MM-DD");
      this.nextdate = "";
      this.prevdate = "";
      this.show_day_page();
    },
    // カレンダーから表示
    // TODO : いきなり編集中ではじまってしまうことがある？？？
    show_calender_page: function () {
      this.nextdate = "";
      this.prevdate = "";
      this.show_day_page();
    },
    // 次の日に進む
    go_next_day: function () {
      if (this.nextdate != "" && !this.is_editing) {
        this.prevdate = this.date; // TODO：効かないことある？取り急ぎ下でキャンセル
        this.prevdate = "";
        this.date = this.nextdate;
        this.nextdate = "";
        this.show_day_page();
        return true;
      } else {
        return false;
      }
    },
    // 前の日に戻る
    go_prev_day: function () {
      if (this.prevdate != "" && !this.is_editing) {
        this.nextdate = this.date; // TODO：効かないことある？取り急ぎ下でキャンセル
        this.nextdate = "";
        this.date = this.prevdate;
        this.prevdate = "";
        this.show_day_page();
        return true;
      } else {
        return false;
      }
    },
    // 前後の日を計算
    calc_prev_next_day: function () {
      // 今日が空の場合はいったんダミーを追加
      if (this.day_element_idx < 0) {
        let dummy_save_data = [];

        // 日付だけは特別扱い
        dummy_save_data[0] = this.date;

        this.$root.all_data.push(dummy_save_data);
      }

      // ソート
      this.$root.all_data.sort();

      // 今日の日付の前後の日付を探索して設定
      for (let i = 0; i < this.$root.all_data.length; i++) {
        let day_element = this.$root.all_data[i];
        if (day_element[0] == this.date) {
          if (i > 0) {
            this.prevdate = this.$root.all_data[i - 1][0];
          } else {
            this.prevdate =
              this.$root.all_data[this.$root.all_data.length - 1][0];
          }

          if (i < this.$root.all_data.length - 1) {
            this.nextdate = this.$root.all_data[i + 1][0];
          } else {
            this.nextdate = this.$root.all_data[0][0];
          }

          // 今日が空だった場合はダミーを削除
          if (this.day_element_idx < 0) {
            this.$root.all_data.splice(i, 1);
          }

          break;
        }
      }
    },
    // 今日でないかどうか
    is_not_today: function () {
      return this.date != dayjs(new Date()).format("YYYY-MM-DD");
    },
    // データをロードして初期化
    load_and_init_all_data: async function () {
      // 一旦無条件でローカルを見に行って本日のページから表示
      await this.load_all_data_from_local();
      this.show_today_page();

      // オンラインかつ認証中ならサーバを見に行く（データがあれば後から更新)
      if (this.$root.is_online && this.$root.is_login) {
        // サーバ読み込み
        // Sheetの存在確認（名前で検索)
        this.spread_sheet_id = await GetSheetId(
          this.SPREAD_SHEET_FILE_NAME
        ).catch(async (error) => {
          alert("Sheet存在確認失敗" + response);
          await this.UpdateTimeStampOnLocal(null);
          this.spread_sheet_id = null;
          console.log("response==null");
        });
        console.log("GetSheetId() done");

        // Sheetが存在した場合は取得を進める
        if (this.spread_sheet_id) {
          // サーバデータ取得
          let temp_all_data;
          let response = await GetSheet(
            this.spread_sheet_id,
            this.SPREAD_SHEET_NAME
          ).catch(async (error) => {
            alert("サーバデータ取得失敗" + error);
            await this.UpdateTimeStampOnLocal(null);
            response = null;
            console.log("response==null");
          });
          console.log("GetSheet() done:" + Date.now());

          // タイムスタンプ取得成功時
          if (response) {
            let server_time_stamp;
            if (response.result.values != null) {
              temp_all_data = response.result.values; // 全データも取っておく
              server_time_stamp = Number(
                response.result.values[0][this.attributes.length + 1]
              ); // タイムスタンプ
            } else {
              server_time_stamp = 0;
            }

            let local_server_time_stamp = await idb.globals.get(
              "server_time_stamp"
            );

            if (local_server_time_stamp != null) {
              local_server_time_stamp = local_server_time_stamp.value;
            }
            let is_local_prior = true;

            // ローカルの同期記録がnull：未同期→選択
            if (local_server_time_stamp == null) {
              // サーバにはデータがある場合
              if (server_time_stamp != 0) {
                // ローカルデータはない場合
                if ((await idb.globals.get("all_data")) == null) {
                  // 同一アカウントの新端末やリセット・インストール直後は無条件でサーバ優先
                  alert("Googleドライブのデータを取得します。");
                  is_local_prior = false;
                }
                // ローカルデータもある場合はどちらが優先か問う
                // ローカル優先の聞き方 : TODO：オフライン中に他で更新してしまったケースもあるので...追ってカスタムにする
                else {
                  if (
                    !confirm(
                      "最新のローカル更新がGoogleドライブと未同期です。ローカルの更新をGoogleドライブに保存しますか？"
                    )
                  ) {
                    // サーバ優先：後でサーバから取得時にタイムスタンプ更新される
                    is_local_prior = false;
                  }
                }
              }
              // サーバにはない場合
              else {
                // 無条件でローカル優先、同期
                await this.save_all_data_to_cloud();
              }
            }
            // サーバの方が新しい：別端末で更新→選択
            // サーバ優先の聞き方
            else if (local_server_time_stamp < server_time_stamp) {
              if (
                confirm(
                  this.get_local_server_update_string(
                    local_server_time_stamp,
                    server_time_stamp
                  ) +
                    "Googleドライブに新しい更新があります。Googleドライブの更新をローカルに保存しますか？"
                )
              ) {
                // サーバ優先：後でサーバから取得時にタイムスタンプ更新される
                is_local_prior = false;
              } else {
                // ローカル優先：Googleドライブ側の更新を棄却するレアケース、同期
                await this.save_all_data_to_cloud();
              }
            }
            // サーバの方が古い：通常はあり得ないケース...一応選択
            // ローカル優先の効き方
            else if (local_server_time_stamp > server_time_stamp) {
              if (
                !confirm(
                  this.get_local_server_update_string(
                    local_server_time_stamp,
                    server_time_stamp
                  ) +
                    "Googleドライブに古い更新があります。ローカルの更新をGoogleドライブに保存しますか？"
                )
              ) {
                // サーバ優先：もともとレアケース、後でサーバから取得時にタイムスタンプ更新される
                is_local_prior = false;
              } else {
                // ローカル優先：もともとレアケース、サーバに同期
                await this.save_all_data_to_cloud();
              }
            }
            // 同期：どちらでも同じだが速いのでローカルから読む、追加同期不要
            else {
              this.$root.server_time_stamp = local_server_time_stamp; // クラウド同期アイコン更新用
            }

            // サーバから取得
            if (!is_local_prior) {
              // Sheetsから最新データを取得
              if (temp_all_data != null) {
                // ヘッダ削除
                if (temp_all_data[0][0] == "date") {
                  temp_all_data.shift();
                }
                this.$root.all_data = temp_all_data;
                this.$root.all_data.sort();
              }

              // ローカル側のタイムスタンプとデータ本体を更新(同期)
              await this.UpdateTimeStampOnLocal(server_time_stamp);
              await idb.globals.put({
                key: "all_data",
                value: this.$root.all_data,
              });

              // 本日のページを再表示
              this.show_today_page();
            }
          }
          // タイムスタンプ取得失敗時
          else {
            // ローカルから取得
            alert(
              "Googleドライブ情報の取得に失敗しました。ローカルのデータが使用されます。"
            );
          }
        }
        // 存在しなかったら新規作成してファイルIDを取得
        else {
          let spreadsheetId = await CreateSheet(
            this.SPREAD_SHEET_FOLDER_NAME,
            this.SPREAD_SHEET_FILE_NAME,
            this.SPREAD_SHEET_NAME
          ).catch(async (error) => {
            alert("Sheet作成失敗" + error);
            await this.UpdateTimeStampOnLocal(null);
            spreadsheetId = null;
            console.log("response==null");
          });
          console.log("CreateSheet() done");

          if (spreadsheetId) {
            this.spread_sheet_id = spreadsheetId;
          }
        }
      }
    },
    // all_data から dateのday_elementのインデックスを取得
    getDayElementIdxByDate: function (all_data, date) {
      if (all_data == null) {
        return -1;
      }

      for (let i = 0; i < all_data.length; i++) {
        let day_element = all_data[i];
        if (day_element[0] == date) return i;
      }

      return -1;
    },
    // ローカル上のタイムスタンプを更新
    UpdateTimeStampOnLocal: async function (time_stamp) {
      await idb.globals.put({ key: "server_time_stamp", value: time_stamp });
      this.$root.server_time_stamp = time_stamp;
    },
    // ローカルからデータ取得
    load_all_data_from_local: async function (is_server_available) {
      let local_all_data = await idb.globals.get("all_data");
      if (local_all_data != null) {
        this.$root.all_data = local_all_data.value;

        // 一時的：いっぺん全部のnullに""を入れる
        for (let j = 0; j < this.$root.all_data.length; j++) {
          let tmp_day = ["", "", "", "", "", "", "", "", ""];
          for (let i = 0; i < this.$root.all_data[j].length; i++) {
            if (this.$root.all_data[j][i] != null) {
              tmp_day[i] = this.$root.all_data[j][i];
            }
          }
          this.$root.all_data[j] = tmp_day.slice();
        }
      }
    },
    // ローカルとサーバのタイムスタンプ文字列
    get_local_server_update_string(local_server_time_stamp, server_time_stamp) {
      return (
        "ローカル:" +
        dayjs(new Date(Number(local_server_time_stamp))).format(
          "YYYY-MM-DD (ddd) HH:mm:ss"
        ) +
        "\n" +
        "サーバー:" +
        dayjs(new Date(Number(server_time_stamp))).format(
          "YYYY-MM-DD (ddd) HH:mm:ss"
        ) +
        "\n"
      );
    },
  },
});
